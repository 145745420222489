import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Lockless = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`ナンバー認識システム「ロックレス」 | ${title}`}</title>
        <meta
          name="description"
          content="IT技術を駆使したナンバー認識システムの開発により、車両を特定して入出庫を管理。業界初、ロックレスを実現いたしました。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>ナンバー認識システム「ロックレス」</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>ナンバー認識システム「ロックレス」</li>
        </div>
      </div>

      <div className="main-wrapper lockless">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>ナンバー認識システム「ロックレス」</h2>
            <div className="common-btn pc-only">
              <a
                href="/assets/files/whitepaper/itc-numberrecognition.pdf"
                target="_blank"
              >
                カタログダウンロード
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>できました。ロック板の無い、はじめての駐車場。</h4>

            <h5>ここがアイテック！</h5>

            <div className="summary full">
              <ul>
                <li>
                  IT技術を駆使したナンバー認識システムの開発により、車両を特定して入出庫を管理。業界初、ロックレスを実現。
                </li>
                <li>
                  インターネット回線を利用して、カメラのライブ映像を遠隔監視できるのに加え、過去の画像検索も瞬時に可能。運営の手間、トラブルを解消します。
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>
              ロック板をなくす。駐車場が一変する。駐車場は、これからこうなる。
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <p>
              ナンバーをしっかり読み取る目が、風景を一新し、集客力アップに貢献します。
            </p>

            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/pc/service-lockless-01.webp"
              />
              <img
                className="pc-only lockless-01"
                src="/assets/images/pc/service-lockless-01.png"
                alt=""
              />
            </picture>
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/sp/service-lockless-01.webp"
              />
              <img
                className="sp-only lockless-01"
                src="/assets/images/sp/service-lockless-01.png"
                height="688"
                alt=""
              />
            </picture>

            <h5>ナンバー認識（NN）システム「ロックレス」のしくみ</h5>

            <div className="separate-area">
              <div className="left-cont">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-lockless-02.webp"
                  />
                  <img
                    className="lockless-02"
                    src="/assets/images/common/service-lockless-02.png"
                    alt=""
                  />
                </picture>
                <p className="fig-title">ナンバー認識ポール</p>
              </div>

              <div className="right-cont">
                <ul className="small-points">
                  <li>
                    メガピクセルカメラ（3M）と、ナンバー認識ソフト。この新システムが、昼間はもちろん夜間でも確実にナンバーを認識。
                  </li>
                  <li>
                    これにより、車両をしっかり特定して入出庫を管理できるため、ロック板は不要に。
                  </li>
                  <li>
                    しかも、インターネット回線を利用して、場内の遠隔監視や、過去の画像検索も可能。
                  </li>
                  <li>
                    駐めやすく、トラブルのない、新世代の駐車場を実現します。
                  </li>
                </ul>
              </div>
            </div>

            <h5>ナンバー認識システム「ロックレス」のメリット</h5>

            <div className="summary full">
              <ul>
                <li>
                  ロック板を乗り越えなくてよいので、圧倒的に駐めやすく。場内での接触事故などを減らすことにつながり、車高の低いクルマでアンダーボディをこする心配もなくなるため、より多くのお客様に安心して駐めていただけます。
                </li>
                <li>
                  清掃がしやすくなり、いつもきれいな車室で、お客様に気持ちよく利用していただけます。また、メンテナンス費用の軽減にもつながります。
                </li>
              </ul>
            </div>

            <table className="advantage">
              <tbody>
                <tr>
                  <th>利点</th>
                  <th>ロック板</th>
                  <th>ロックレス</th>
                </tr>
                <tr>
                  <td>車室への入出しやすさ</td>
                  <td>△</td>
                  <td>◎</td>
                </tr>
                <tr>
                  <td>出庫時のロック板の確認</td>
                  <td>必要</td>
                  <td>必要なし</td>
                </tr>
                <tr>
                  <td>ロック板のメンテナンス</td>
                  <td>必要</td>
                  <td>必要なし</td>
                </tr>
                <tr>
                  <td>車両への損傷トラブル</td>
                  <td>ある</td>
                  <td>ない</td>
                </tr>
                <tr>
                  <td>不注意出庫による機器の損傷</td>
                  <td>ある</td>
                  <td>ない</td>
                </tr>
                <tr>
                  <td>不払い出庫車両の特定・追跡</td>
                  <td>できない※</td>
                  <td>できる</td>
                </tr>
                <tr>
                  <td>事故・不正行為の把握</td>
                  <td>できない※</td>
                  <td>できる</td>
                </tr>
                <tr>
                  <td>機器のトラブル時の緊急対応</td>
                  <td>必要</td>
                  <td>必要なし</td>
                </tr>
              </tbody>
            </table>

            <p className="notes">※ 防犯カメラを設置していない場合</p>

            <div className="separate-area">
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-lockless-soft.webp"
                  />
                  <img
                    className="soft"
                    src="/assets/images/common/service-lockless-soft.png"
                    height="635"
                    alt=""
                  />
                </picture>
                <p className="soft-real-title">
                  認識ソフト テスト画像（昼／夜)
                </p>
              </div>
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-lockless-real.webp"
                  />
                  <img
                    className="real"
                    src="/assets/images/common/service-lockless-real.png"
                    height="635"
                    alt=""
                  />
                </picture>
                <p className="soft-real-title">実画像（昼／夜)</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>ナンバー認識ポール標準仕様</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-02">
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-lockless-03.webp"
              />
              <img
                className="lockless-03"
                src="/assets/images/common/service-lockless-03.png"
                alt=""
              />
            </picture>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法 </th>
                  <td>H1450mm×W215mm×D215mm（突起部含む）</td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約7.5kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>DC24V</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>待機時4W 動作時最大 18W(DC24V)</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度　0℃～45℃　湿度　結露しないこと</td>
                </tr>
                <tr>
                  <th>カメラ部</th>
                  <td>画像素子 3メガピクセル(300万画素)</td>
                </tr>
                <tr>
                  <th>CPU＋超高速演算部装置部メモリー</th>
                  <td>256MB</td>
                </tr>
                <tr>
                  <th>車両検知センサー</th>
                  <td>
                    ループコイルセンサー、ハイブリッドセンサー(電波＋音波)、超音波センサー
                  </td>
                </tr>
                <tr>
                  <th>フラッシュライト</th>
                  <td>超高輝度LEDライト</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Lockless
